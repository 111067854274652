import React, { useEffect } from "react";
import Content from "./components/Content";
import Header from "./components/Header";
import { ProfileCardStyled } from "./style";
import { useRouter } from "next/router";

const ProfileCard = ({ data, listing, setOpen, openModel, page = "" }) => {

  const router = useRouter();
  
  return (
    <ProfileCardStyled>
      <Header
        data={data}
        listing={listing}
        setOpen={setOpen}
        openModel={openModel}
        page={page}
      />
      <Content data={data} />
    </ProfileCardStyled>
  );
};

export default ProfileCard;
