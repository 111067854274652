import React from "react";
import User from "../../../common/UserInfo";
import { ContentStyled, FeatureWrapper, InfoCol, InfoRow, Divider, Price } from "./style";
import Features from "../Features";
import { useTranslation } from "next-i18next";
import Typography from "@mui/material/Typography";
import { priceFormate } from "../../../../utils/utils";
import Image from "next/image";
import { useRouter } from "next/router";
import { NoFlipInputWrapper } from "../../../ProfileForm/style";
import uiCalendar from '../../../../../public/images/svg/u_calender.png'
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { momentArLocale } from '../../../../utils/utils';

const Content = ({ data }) => { 
  // console.log('========data', data);
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const router = useRouter();

  let propertyTypeText = `${data?.propertyTypeText} ${t("property-type", {
    listingType: t(data?.listingType),
  })} ${t("in")} ${data?.district}`;

  if (propertyTypeText.length <= 36) {
    propertyTypeText = propertyTypeText
  } else {
    propertyTypeText = propertyTypeText.slice(0, 36) + '...'
  }


  let price;

  if (data?.documentType === 0) {
    price = t("price_sar", {
      price: priceFormate(data?.price),
    });
  } else if (data?.documentType === 1) {
    price = t("price_sar_start", {
      price: priceFormate(data?.price),
    });
  } else {
    price = t("price_sar_from", {
      price: priceFormate(data?.price),
    });
  }
  if (price.length <= 6) {
    price = price
  } else {
    price = price.slice(6) + '...'
  }


  if (router.locale == "en") {
    moment.locale(router.locale);
  } else {
    moment.locale(router.locale, momentArLocale);
  }

  // console.log("data?.rentTypeText => ", data?.rentTypeText)

  return (
    <ContentStyled>
      {/* <Typography
        variant={"body2"}
        size={"small"}
        sx={{ marginBottom: "12px", color: "text.main" }}
      >
        {/* {data?.propertyTypeText}    {t("property-type", {
          listingType: t(data?.listingType),
        })} {
          t("in") + " " + data.district
        }

        {propertyTypeText}
      </Typography> */}
      
      <h2 style={{fontSize: 15, fontWeight: 'bold', marginBottom: 10}}>{propertyTypeText}</h2>

      <Stack direction="row" >
        <Image
          src={require("../../icons/location.svg")}
          width={15}
          height={11}
          alt="Bed icon"
        />
        <Typography
          variant="body1"
          size="smallest"
          sx={{ marginBottom: "12px", color: "text.main" }}
        >
          {data?.city + ", " + data?.district}

        </Typography>
      </Stack>
      <Stack spacing={1} direction="row">
        <Box flex={1}>
          <div style={{ marginBottom: 10 }}>
            {data?.propertyType == "Villa" ||
              data?.propertyType == "Apartment" ||
              data?.propertyType == "Roof" ? (
              <>
                {data?.documentType == 2 && data?.availableUnits > 0 && (
                  <>  <Typography
                    variant="body2"
                    size="big  "
                    sx={{
                      color: "text.main",
                      mb: "3px",
                    }}
                  >
                    {t("available_units", {
                      unit: data?.availableUnits,
                    })}
                  </Typography>
                    <span style={{ border: '1px solid #EFEFEF' }}>  </span>
                  </>
                )}
                <Stack direction={"row"} spacing={1}>
                  {data?.numberBedrooms && (
                    <>  <FeatureWrapper>
                      <Image
                        src={require("../../icons/bed-icon2.svg")}
                        width={15}
                        height={11}
                        alt="Bed icon"
                      />

                      <Typography
                        variant="body1"
                        size="smallest"
                        sx={{ ml: "3px" }}
                      >
                        {data?.numberBedrooms}
                      </Typography>

                    </FeatureWrapper>
                      <span style={{ border: '1px solid #EFEFEF' }}>  </span>
                    </>
                  )}

                  {data?.numberBathrooms && (
                    <> <FeatureWrapper>
                      <Image
                        src={require("../../icons/bath-icon2.svg")}
                        width={15}
                        height={11}
                        alt="Bed icon"
                      />
                      <Typography
                        variant="body1"
                        size="smallest"
                        sx={{ ml: "3px" }}
                      >
                        {data?.numberBathrooms}
                      </Typography>
                      {/* <Divider /> */}
                    </FeatureWrapper>
                      <span style={{ border: '1px solid #EFEFEF' }}>  </span>
                    </>
                  )}
                  {data?.lotSize && data?.lotSize > 0 && (
                    <> <FeatureWrapper>
                      <Image
                        src={require("../../icons/area-icon2.svg")}
                        width={17}
                        height={13}
                        alt="area icon"

                      />
                      {locale == "en" ? (
                        <Typography
                          variant="body1"
                          size="smallest"
                          sx={{ ml: "3px" }}
                          style={{ marginTop: '-3px' }}
                        >
                          {data?.lotSize} m<sup>2</sup>
                        </Typography>

                      ) : (
                        <NoFlipInputWrapper>
                          <Typography
                            variant="body1"
                            size="smallest"
                            sx={{ ml: "3px" }}
                            style={{ marginTop: '-3px' }}
                          >
                            {data?.lotSize} m<sup>2</sup>
                          </Typography>

                        </NoFlipInputWrapper>
                      )}
                    </FeatureWrapper>
                    </>
                  )}
                </Stack>{" "}
              </>
            ) : (
              <>
                {data?.documentType == 0 && (
                  <>
                    <Stack direction={"row"} spacing={1}>
                      {data?.numberBedrooms && (
                        <><FeatureWrapper>
                          <Image
                            src={require("../../icons/bed-icon2.svg")}
                            width={15}
                            height={11}
                            alt="Bed icon"
                          />

                          <Typography
                            variant="body1"
                            size="smallest"
                            sx={{ ml: "3px" }}
                          >
                            {data?.numberBedrooms}
                          </Typography>
                        </FeatureWrapper>
                          <span style={{ border: '1px solid #EFEFEF' }}>  </span>
                        </>
                      )}
                      {data?.numberBathrooms && (
                        <> <FeatureWrapper>
                          <Image
                            src={require("../../icons/bath-icon2.svg")}
                            width={15}
                            height={11}
                            alt="Bed icon"
                          />
                          <Typography
                            variant="body1"
                            size="smallest"
                            sx={{ ml: "3px" }}
                          >
                            {data?.numberBathrooms}
                          </Typography>
                          {/* <Divider /> */}
                        </FeatureWrapper>
                          <span style={{ border: '1px solid #EFEFEF' }}>  </span>
                        </>
                      )}
                      {data?.lotSize && data?.lotSize > 0 && (
                        <><FeatureWrapper>
                          <Image
                            src={require("../../icons/area-icon2.svg")}
                            width={17}
                            height={13}
                            alt="area icon"
                          />
                          {locale == "en" ? (

                            <Typography
                              variant="body1"
                              size="smallest"
                              sx={{ ml: "3px" }}
                              style={{ marginTop: '-3px' }}
                            >
                              {data?.lotSize} m<sup>2</sup>
                            </Typography>
                          ) : (
                            <NoFlipInputWrapper>
                              <Typography
                                variant="body1"
                                size="smallest"
                                sx={{ ml: "3px" }}
                                style={{ marginTop: '-3px' }}
                              >
                                {data?.lotSize} m<sup>2</sup>
                              </Typography>
                            </NoFlipInputWrapper>
                          )}
                        </FeatureWrapper>
                          <span style={{ border: '1px solid #EFEFEF' }}>  </span>
                        </>
                      )}
                    </Stack>{" "}
                  </>
                )}
                {data?.documentType == 1 && (
                  <Typography
                    variant="body2"
                    size="big  "
                    sx={{
                      color: "text.main",
                    }}
                  >
                    {data?.title}
                  </Typography>
                )}
              </>
            )}
          </div>

          {/* <Typography
            variant="body3"
            size="extraSmall"
            sx={{ color: "text.main" }}
            style={{ marginBottom: 10 }}
          >
            {t("city_district", {
              district: data?.district,
              city: t(data?.city),
            })}
          </Typography>

          {data?.advertisementNumber !== undefined && (
            <Typography
              variant="body3"
              size="extraSmall"
              sx={{ color: "text.main" }}
              style={{ marginTop: 5 }}
            >
              {t("advertisement_number", { adnum: data?.advertisementNumber })}
            </Typography>
          )} */}

        </Box>
        <Box flex={1} textAlign="right">
          <div style={{ textAlign: router.locale == "en" ? 'right' : 'left' }}>
            {/* <Box
              key="daysago"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{
                marginBottom: 10
              }}
            >
              <Image src={uiCalendar} width={18} height={18} alt="calendar" />
              <span style={{ fontSize: 12, marginLeft: router.locale == "en" ? 5 : 0, marginRight: router.locale == "en" ? 0 : 5 }}>
                {moment(new Date(data.creationDateTime), 'YYYY-MM-DD HH:mm:ss').fromNow()}
              </span>
            </Box> */}

            <Typography
              variant="body1"
              size="tiny"
              sx={{
                marginBottom: (theme) => theme.typography.pxToRem(6),
                color: "text.main",
              }}
            >
              {data?.documentType == 0
                ?
                <>
                  {/* {data?.listingType === "Rent" && data?.rentTypeText == undefined  ? t("yearly") + " " : ""} */}
                  <Price>
                    {priceFormate(data?.price) + " "}
                  </Price>
                  {t("SAR")}
                </>
                : data?.documentType == 1
                  ?
                  <>

                    {t("price_sar_start2") + " "}

                    <Price>
                      {priceFormate(data?.price) + " "}
                    </Price>

                    {t("SAR")}
                  </>
                  : <>

                    {t("price_sar_start2")}
                    <Price>
                      {priceFormate(data?.price) + " "}
                    </Price>
                    {t("SAR")}

                  </>}

              {/* {price} */}
              {data?.rentTypeText == undefined ? "" : `/${data?.rentTypeText}`}
            </Typography>
          </div>

          {/* <Typography
            variant="body3"
            size="extraSmall"
            sx={{ color: "text.main" }}
          >
            {t("property-type", {
              listingType: t(data?.listingType),
            })}
          </Typography> */}
        </Box>

      </Stack>

      <Divider />
      {/* <Stack spacing={1} direction="row"> */}
      {/* <Box > 
          <InfoRow>
           <InfoCol> */}
      <User data={data} thick />
      {/* </InfoCol> 
          </InfoRow> 
        </Box>  */}

      {/* <Box
          key="daysago"
          justifyContent="flex-end"
          flex={2}
          style={{ marginTop: 5 }}
        >
          {data?.creationDateTime &&
            <span style={{ fontSize: 10, marginLeft: router.locale == "en" ? 35 : 0, marginRight: router.locale == "en" ? 0 : 5 }}>
              {moment(new Date(data.creationDateTime), 'YYYY-MM-DD HH:mm:ss').fromNow()}
            </span>
          }

          {data?.advertisementNumber !== undefined && (
            <Typography
              // variant={"body1"}
              // size={"extraSmall"}
              sx={{ color: "text.main" }}
              style={{ marginTop: 5 }}
            >
              <span style={{ fontSize: 10, marginLeft: router.locale == "en" ? 35 : 0, marginRight: router.locale == "en" ? 0 : 5 }}>{t("Ad_No") + data?.advertisementNumber}</span>
            </Typography>
          )}
        </Box> */}

      {/* </Stack> */}
    </ContentStyled>
  );
};

export default Content;
