import { useRouter } from "next/router";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../../../hooks/axios";

//to make a property unLike
const useMakeUnLike = (documentType, projectId = false) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const router = useRouter();

  return useMutation(
    async (values) => {
      let ids = _.isObject(values) == true ? values?.id : values;
      let url =
        documentType == 0
          ? `/reactions/v1/listings/${ids}/unlike`
          : documentType == 1
          ? `/reactions/v1/project/${ids}/unlike`
          : `/reactions/v1/projectmodel/${ids}/unlike`;
      const { data } = await axios.post(url);
      return data;
    },
    {
      onSuccess: (data, values) => {
        let ids = _.isObject(values) == true ? values?.id : values;
        if (documentType != 2) {
          let homeLikedItem = queryClient.getQueryData(["home-all"]);
          if (homeLikedItem) {
            let currentItem = _.find(homeLikedItem?.data?.results, {
              id:
                documentType == 0
                  ? data?.data?.listingId
                  : documentType == 1
                  ? data?.data?.projectId?.toString()
                  : data?.data?.projectModelId,
            });

            if (currentItem) {
              currentItem.isLiked = !currentItem?.isLiked;
            }
          }

          let likedItem = queryClient.getQueryData([
            "property",
            "detail",
            ids.toString(),
          ]);

          if (likedItem) {
            likedItem.data.isLiked = false;
            queryClient.setQueryData(
              ["property", "detail", data?.data?.listingId],
              likedItem
            );
          }
        }
        // let likedItem2 = queryClient.getQueryData(["projects", ids.toString()]);
        // if (likedItem2) {
        //   likedItem.data.isLiked = true;
        //   queryClient.setQueryData(
        //     ["projects", data?.data?.projectId],
        //     likedItem
        //   );
        // }
        let likedItem2 = queryClient.getQueryData([
          "project",
          "model",
          "listing",
          projectId,
        ]);

        if (likedItem2) {
          let currentItem = _.find(likedItem2?.data?.results, {
            id: ids,
          });
          currentItem.isLiked = false;
          queryClient.setQueryData(
            ["project", "model", "listing", projectId],
            likedItem2
          );
        }

        let likedItem3 = queryClient.getQueryData([
          "project",
          "model",
          "detail",
          ids,
        ]);
        if (likedItem3) {
          likedItem3.data.isLiked = false;
          queryClient.setQueryData(
            [
              "project",
              "model",
              "detail",
              ,
              data?.data?.projectModelId?.toString(),
            ],
            likedItem3
          );
        }
        // if (projectId) {
        //   let likedItem4 = queryClient.getQueryData([
        //     "project",
        //     "model",
        //     "listing",
        //     projectId,
        //   ]);

        //   if (likedItem4) {
        //     likedItem4.data.isLiked = false;
        //     queryClient.setQueryData(
        //       ["project", "model", "listing", projectId],
        //       likedItem4
        //     );
        //   }
        // }

        let recentLiked = queryClient.getQueryData([
          "recently-viewed",
          "listing",
          values?.page,
        ]);
        if (recentLiked) {
          let currentItem = _.find(recentLiked?.data?.results, {
            id: values?.id,
          });

          if (currentItem) {
            currentItem.isLiked = !currentItem?.isLiked;
          }
        }

        let likeLiked = queryClient.getQueryData([
          "liked",
          "listing",
          values?.page,
        ]);
        if (likeLiked) {
          let currentItem = _.find(likeLiked?.data?.results, {
            id: values?.id,
          });

          if (currentItem) {
            currentItem.isLiked = !currentItem?.isLiked;
          }
        }

        let similarLiked = queryClient.getQueryData([
          "similar",
          "listing",
          router?.query?.slug,
        ]);
        if (similarLiked) {
          let currentItem = _.find(similarLiked?.data?.results, {
            id: values?.id,
          });
          if (currentItem) {
            currentItem.isLiked = !currentItem?.isLiked;
          }
        }
        queryClient.invalidateQueries(["properties"]);
        queryClient.invalidateQueries(["project", "listing"]);
        queryClient.invalidateQueries(["area", "listing"]);
        queryClient.invalidateQueries(["agent", "listing"]);
        queryClient.invalidateQueries(["properties"]);
        queryClient.invalidateQueries(["project", "listing"]);
        queryClient.invalidateQueries(["liked", "listing"]);
        queryClient.invalidateQueries(["similar", "listing"]);
        queryClient.invalidateQueries(["property", "detail"]);
        queryClient.invalidateQueries(["recently-viewed", "listing"]);
        // queryClient.invalidateQueries(["project", "model", "listing"]);
      },

      onError: () => {
        queryClient.invalidateQueries(["home-all"]);
        queryClient.invalidateQueries(["liked", "listing"]);

        queryClient.invalidateQueries(["properties"]);
        queryClient.invalidateQueries(["project", "listing"]);
      },
    }
  );
};
export default useMakeUnLike;
