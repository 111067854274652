import Image from "next/image";
import React from "react";
import { Avatar, Info, UserInfoStyled, UserVerified } from "./style";
import UserAvatar from "../../../../public/images/default-avatar.jpg";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";
import moment from "moment";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
const UserInfo = ({ data, large, thick }) => {
  // ?.licenseNumber ?newValue?.licenseNumber : newValue?.userName
  const { t } = useTranslation("common");
  const router = useRouter()
  let sellerName;
  if (data?.sellerName?.length <= 23) {
    sellerName = data?.sellerName
  } else {
    sellerName = data?.sellerName?.slice(0, 23) + '...'
  }

  return (
    <UserInfoStyled>
      <div style={{ position: "relative" }}>
        <Avatar large={large ? "true" : ""}>
          <Link href={`/agent/${data?.sellerUsername}`}>
            <Image
              src={data?.sellerProfileUrl ? data?.sellerProfileUrl : UserAvatar}
              alt={`image of ${data?.sellerUsername}`}
              fill
              style={{ objectFit: "contain", objectPosition: "center" }}
            />
          </Link>
        </Avatar>
        {true && (
          <UserVerified
            src={require("../../../../public/images/user-verified.png")}
            alt={t("verified_user_icon")}
            large={large ? "true" : ""}
          />
        )}
      </div>
      <Info>
        <Stack direction="row" >
          <Box flex={2} >
            <Link href={`/agent/${data?.sellerUsername}`}>
              <Typography
                variant={thick ? "body2" : "body1"}
                size={thick ? "small" : "extraSmall"}
                sx={{ marginBottom: "5px", color: "text.main" }}
              >
                {router.locale == "ar" ?
                  <span style={{ fontSize: 12 }}>
                    {sellerName}
                  </span> :
                  sellerName
                }

              </Typography>
            </Link>
          </Box>

          <Box flex={1} textAlign="right">
            <div style={{ textAlign: router.locale == "en" ? 'right' : 'left' }}>
              {data?.creationDateTime &&
                <Typography
                  variant={"body1"}
                  size={"tiny"}
                  sx={{ marginBottom: "5px", color: "text.main" }}
                >
                  {moment(new Date(data?.creationDateTime), 'YYYY-MM-DD HH:mm:ss').fromNow()}
                </Typography>
              }
            </div>
          </Box>
        </Stack>

        <Stack direction="row">
          <Box flex={1} >

            <div style={{ direction: "ltr" }}>
              <Typography
                variant="body1En"
                size="tiny"
                sx={{ color: "text.main", textAlign: "left" }}
              >
                @{data?.sellerUsername}
              </Typography>
            </div>
          </Box>

          {data?.advertisementNumber !== undefined && (
            <Box flex={3} textAlign="right">

              <Typography
                variant={"body1"}
                size={"tiny"}
                sx={{ marginBottom: "5px", color: "text.main" }}
              >
                {t("Ad_No") + data?.advertisementNumber}
              </Typography>

              {data?.sellerLicenseNumber !== undefined && (
                <div style={{ direction: "ltr", marginBottom: 5 }}>
                  <Typography
                    variant="body1"
                    size="tiny"
                    sx={{ color: "text.main" }}
                  >
                    {t("fal_no")}: {data?.sellerLicenseNumber}
                  </Typography>
                </div>
              )}
            </Box>
          )}
        </Stack>
      </Info>
    </UserInfoStyled>
  );
};

export default UserInfo;
