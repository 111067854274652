import React from "react";
import Typography from "@mui/material/Typography";
import { PropertyType, TopHeaderOverlayStyled } from "./style";
import { useTranslation } from "next-i18next";
import Features from "../Features";
import { useRouter } from "next/router";

const TopHeaderOverlay = ({ data }) => {
  const { t } = useTranslation("common");
  const floor = data?.floorNo == 0 ? t("ground_floor") : data?.floorNo;
  let Title = data?.title;
  let TrimTitle = Title?.split(",");
  const { locale } = useRouter();

  return (
    <TopHeaderOverlayStyled>
      {/* <PropertyType>
        <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
          <Typography
            variant="body2"
            size={locale == "en" ? "smaller" : "small"}
            sx={{ color: "#fff" }}
            noWrap
          >
            {data?.documentType == 0
              ? data?.propertyTypeText
              : data?.documentType == 1
              ? t("project")
              : ""}
          </Typography>
        </div>
        {data?.documentType == 2 && (
          <Typography
            variant="body2"
            size={locale == "en" ? "smaller" : "small"}
            sx={{ color: "#fff" }}
          >
            {data?.propertyTypeText+' '+TrimTitle[0]}
          </Typography>
          // <Typography
          //   variant="body2"
          //   size="small"
          //   sx={{ color: "#fff" }}
          //   dangerouslySetInnerHTML={{
          //     __html: t("new_project_flag", {
          //       property: t(data?.propertyType),
          //       title: TrimTitle[0],
          //     }),
          //   }}
          // />
        )}
      </PropertyType> */}
      <Features data={data} />
    </TopHeaderOverlayStyled>
  );
};

export default TopHeaderOverlay;
