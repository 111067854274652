import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const HeaderStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  borderRadius: "10px 10px 0px 0px",
  maxHeight: "260px",
  minHeight: "260px",
  overflow: "hidden",
  boxShadow: "rgb(0 0 0 / 30%) 6px 16px 51px -30px",
  // "box-shadow": "rgb(0 0 0 / 56%) 6px 16px 51px -30px",
  "& img": {
    zIndex: 15,
  },
  "& .swiper-slide": {
    minHeight: "300px",
  },
  "& .swiper-pagination-bullet-active": {
    backgroundColor: "#fff",
  },
}));

const PropertyType = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  top: "8px",
  right: "8px",
  background: "rgba(0, 0, 0, 0.6)",
  padding: "5px 10px",
  borderRadius: "5px",
  zIndex: 99,
}));

const ListingType = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  top: "8px",
  left: "8px",
  background: "rgba(0, 0, 0, 0.6)",
  padding: "5px 10px",
  borderRadius: "5px",
  zIndex: 99,
}));

const SwiperWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  "& .swiper-pagination-bullets.swiper-pagination-horizontal": {
    bottom: "60px",
  },
  "& .swiper-pagination-bullet": {
    backgroundColor: "#fff",
    opacity: 1,
    boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.25)",
  },
  transition: "300ms all ease-in-out 0ms",
  "&:hover": {
    boxShadow: "rgb(0 0 0 / 20%) 6px 16px 51px -20px",
    transform: "translateY(-4px)",
  },
}));

export { HeaderStyled, PropertyType, ListingType, SwiperWrapper };
