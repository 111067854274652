import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const TopHeaderOverlayStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  top: "5.51px",
  // left: "1px",
  display: "flex",
  zIndex: 99,
}));

const PropertyType = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  background: "#00BEAD",
  borderRadius: "0px 10px 10px 0px",
  padding: theme.direction == "rtl" ? "6px 8px 3px" : "5px 7px 3px",
  marginRight: "16px",
  maxWidth: "130px",
}));

export { TopHeaderOverlayStyled, PropertyType };
