import { useAxios } from "../../../../../hooks/axios";
import { useMutation, useQueryClient } from "react-query";
import _, { values } from "lodash";
import { useRouter } from "next/router";

//to make a property favorite
const useMakeFavorite = (documentType, projectId = false) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const router = useRouter();

  return useMutation(
    async (values) => {
      let ids = _.isObject(values) == true ? values?.id : values;
      let url =
        documentType == 0
          ? `/reactions/v1/listings/${ids}/like`
          : documentType == 1
          ? `/reactions/v1/project/${ids}/like`
          : `/reactions/v1/projectmodel/${ids}/like`;

      const { data } = await axios.post(url);
      return data;
    },
    {
      onSuccess: (data, values) => {
        // console.log('tesss/=sssssssssst' ,projectId ,'documentType' ,documentType);
        let ids = _.isObject(values) == true ? values?.id : values;
        if (documentType != 2) {
          let homeLikedItem = queryClient.getQueryData(["home-all"]);
          if (homeLikedItem) {
            let currentItem = _.find(homeLikedItem?.data?.results, {
              id:
                documentType == 0
                  ? data?.data?.listingId
                  : documentType == 1
                  ? data?.data?.projectId
                  : data?.data?.projectModelId,
            });

            if (currentItem) {
              currentItem.isLiked = !currentItem?.isLiked;
            }
          }

          let likedItem = queryClient.getQueryData(["property", "detail", ids]);
          if (likedItem) {
            // console.log('likedItem' ,likedItem);
            likedItem.data.isLiked = true;
            queryClient.setQueryData(
              ["property", "detail", data?.data?.listingId],
              likedItem
            );
          }
          
        }
        if (projectId) {
          let likedItem3 = queryClient.getQueryData([
            "project",
            "model",
            "listing",
            projectId,
          ]);

          if (likedItem3) {
            // console.log('likedItem3',likedItem3);
            let currentItem = _.find(likedItem3?.data?.results, {
              id: ids,
            });
            currentItem.isLiked = true;
            queryClient.setQueryData(
              ["project", "model", "listing", projectId],
              likedItem3
            );
          }
          let likedItem4 = queryClient.getQueryData([
            "project",
            "model",
            "detail",
            ids,
          ]);

          if (likedItem4) {
            // console.log('likedItem4' ,likedItem4);
            likedItem4.data.isLiked = true;
            queryClient.setQueryData(
              ["project", "model", "detail", ids],
              likedItem4
            );
          }
 
        }

        let recentLiked = queryClient.getQueryData([
          "recently-viewed",
          "listing",
          values?.page,
        ]);
        if (recentLiked) {
          console.log('recentLiked' ,recentLiked);
          let currentItem = _.find(recentLiked?.data?.results, {
            id: values?.id,
          });

          if (currentItem) {
            currentItem.isLiked = !currentItem?.isLiked;
          }
        }

        let similarLiked = queryClient.getQueryData([
          "similar",
          "listing",
          router?.query?.slug,
        ]);
        if (similarLiked) {
          let currentItem = _.find(similarLiked?.data?.results, {
            id: values?.id,
          });
          if (currentItem) {
            currentItem.isLiked = !currentItem?.isLiked;
          }
        }

        // likedItem.data.isLiked = true;
        // queryClient.setQueryData(
        //   ["property", "detail", data?.data?.listingId],
        //   likedItem
        // );
        queryClient.invalidateQueries(["properties"]);
        queryClient.invalidateQueries(["project", "listing"]);
        queryClient.invalidateQueries(["liked", "listing"]);
        queryClient.invalidateQueries(["similar", "listing"]);
        queryClient.invalidateQueries(["property", "detail"]);
        queryClient.invalidateQueries(["recently-viewed", "listing"]);
        queryClient.invalidateQueries(["project", "listing"]);
        queryClient.invalidateQueries(["area", "listing"]);
        queryClient.invalidateQueries(["agent", "listing"]);
        // queryClient.invalidateQueries(["project", "model", "listing"]);

        // queryClient.invalidateQueries(["recently-viewed", "listing"]);
        // queryClient.invalidateQueries(["recently-viewed", "listing"]);
        // queryClient.invalidateQueries(["recently-viewed", "listing"]);
      },
      onError: () => {
        queryClient.invalidateQueries(["home-all"]);
        queryClient.invalidateQueries(["project", "listing"]);
        queryClient.invalidateQueries(["properties"]);
        queryClient.invalidateQueries(["liked", "listing"]);
      },
    }
  );
};

export default useMakeFavorite;
