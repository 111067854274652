import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import React from "react";
import { useRouter } from "next/router";
import { Feature, FeaturesStyled, CheckIcon, ServiceIcon, Icon360 } from "./style";

const Features = ({ data }) => {
  const { t } = useTranslation("common");
  const { locale } = useRouter();

  return (
    <FeaturesStyled>
      <Tooltip title={t("verified")}>
        <Feature active>
          <CheckIcon />
        </Feature>
      </Tooltip>
      {data?.inspectionReportUrl &&
        <Tooltip
          title={t(data?.inspectionReportUrl ? "inspection_available" : "")}
        >
          <Feature active={data?.inspectionReportUrl || false}>
            <ServiceIcon />
          </Feature>
        </Tooltip>
      }


      {data?.virtualTourUrl &&
        <Tooltip title={t(data?.virtualTourUrl ? "3d_available" : "")}>
          <Feature active={data?.virtualTourUrl || false}>
            {/* <Typography variant="body2" size="tiny" sx={{ color: "white.main" }}>
           3D
         </Typography> */}

            <Icon360
              src={require("../../../../../public/images/Icone360.png")}
              alt="Nafath icon" />
          </Feature>
        </Tooltip>
      }

    </FeaturesStyled>
  );
};

export default Features;
