import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {
  ActionsStyled,
  CopyIcon,
  CopyIconWrapper,
  CustomCallIcon,
  CustomDialog,
  CustomEditIcon,
  CustomFavIcon,
  CustomFavIconFill,
  CustomMessageIcon,
  CustomReportIcon,
  CustomShareIcon,
  IconWrapper,
} from "./style";
import Image from "next/image";
import { rgbDataURL, toastOptions, WEB_PATH } from "../../../../utils/utils";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useAuth } from "../../../../context/auth";
import Popover from "@mui/material/Popover";
import useMakeFavorite from "./hooks/useMakeFavorite";
import Box from "@mui/material/Box";
import Report from "../../../PropertyDetails/components/Report/Report";
import Chat from "../../../PropertyDetails/components/Chat/chat";
import useMakeUnLike from "./hooks/useMakeUnLike";
import CircularProgress from "@mui/material/CircularProgress";
import { InlineShareButtons } from "sharethis-reactjs";
import { toast } from "react-toastify";
import DialogContent from "@mui/material/DialogContent";
import useCreateChat from "../../../../hooks/useChat";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { CloseButtonWrapper } from "../../../ProfileForm/style";
import { CustomButton } from "../../../common/Buttons/Buttons";
import { CloseIcon } from "../../../ProfileForm/style";
import { useEffect } from "react";
import { CallIcon } from "./icons";
import { RWebShare } from "react-web-share";
import { useAxios } from "../../../../hooks/axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon
} from "react-share";
import { event } from "../../../../utils/gtm";

const Actions = (data) => {
  // console.log('dat?a' ,data);
  const { t } = useTranslation("common");
  const router = useRouter();
  const axios = useAxios()
  const [anchorEl, setAnchorEl] = useState(null);
  const [shortUrll, setShortUrll] = useState(null);
  const [openModel, setOpen] = useState(false);
  const [openChatModel, setChatOpen] = useState(false);
  const [modalCallOpen, setModalCallOpen] = useState(false);
  const [value, copy] = useCopyToClipboard();
  const { user } = useAuth();
  const { mutate: makeFavorite, isLoading: likeLoading } = useMakeFavorite(
    data?.data?.documentType,
    router?.pathname == "/project/[slug]" ? router?.query?.slug : false
  );
  const [likeCount, setLikeCount] = useState(data?.data?.likeCount);
  const [isLiked, setIsLiked] = useState(data?.data?.isLiked);



  useEffect(() => {
    if (value)
      toast?.success(t("copied"), toastOptions);
  }, [value]);

  useEffect(() => {
    setLikeCount(data?.data?.likeCount);
  }, [data?.data]);

  const { mutate: dislike, isLoading: disLikeLoading } = useMakeUnLike(
    data?.data?.documentType,
    router?.pathname == "/project/[slug]" ? router?.query?.slug : false
  );

  const onCallPopupClose = () => {
    setModalCallOpen(false);
    copy(null);
  };

  const handleFavButtonSelect = (id, liked) => {
    if (user) {
      if (liked == false) {
        setIsLiked(true)
        event({
          action: "listing_like",
          params: {
            "listing_like_id": id
          }
        });
        makeFavorite(id, {
          onSuccess: () => {
            setLikeCount(likeCount + 1);
          },

        }, true);
      } else {
        setIsLiked(false)
        dislike(id, {
          onSuccess: () => {
            setLikeCount(likeCount - 1);
          },
        });
      }
    } else {
      router?.push({
        pathname: "/login",
        query: {
          redirect:
            data?.data?.documentType == 0 ? `/property/${id}` : `project/${id}`,
        },
      });
    }
  };

  const handleCallButtonClick = () => {
    router?.push({
      pathname: "/login",
      query: { redirect: `/property/${data?.data?.id}` },
    });
  };

  const handleShareClick = async (event) => {

    copy(null);
    setAnchorEl(event.currentTarget);
    const res = await axios.get(`/analytics/v1/share/listing/${data.data.id}`)
    copy(null);
    setShortUrll(res.data.data.shortUrl)

  };

  const handleCheckPhone = () => {
    if (user) {
      if (!data?.data?.sellerPhonenumber) {
        // setNumber(data?.sellerPhonenumber);
        toast.error(t("mobile_number_is_not_available"), toastOptions);
      } else {
        setModalCallOpen(user && true);
      }
    } else {
      setModalCallOpen(true);
      // router?.push({
      //   pathname: "/login",
      //   query: { redirect: `/property/${data?.data?.id}` },
      // });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: startChat, isLoading: isChatCreating } = useCreateChat();

  const handleChat = (chatUserId, type) => {
    const linkType = type == 0 ? "property" : "project";
    if (user) {
      startChat(chatUserId);

    } else {
      router?.push({
        pathname: "/login",
        query: { redirect: `/${linkType}/${data?.data?.id}` },
      });
    }
  };

  const handleReport = (type) => {
    const linkType = type == 0 ? "property" : "project";
    if (user) {
      setOpen(true);
    } else {
      router?.push({
        pathname: "/login",
        query: { redirect: `/${linkType}/${data?.data?.id}` },
      });
    }
  };

  const handleEdit = () => {
    if (user) {
      router?.push({
        pathname: `/edit-property/${data?.data?.id}`,
        query: { redirect: `/property/${data?.data?.id}` },
      });
    } else {
      router?.push({
        pathname: "/login",
        query: { redirect: `/property/${data?.data?.id}` },
      });
    }
  };
  const open = anchorEl ? true : false;
  const id = open ? "simple-popover" : "";

  const buttons = [
    <IconButton key="call-icon" onClick={handleCheckPhone} sx={{ p: 0 }}>
      <>
        {/* {user ? (
          <>
            {data?.data?.sellerPhonenumber &&
            data?.data?.sellerPhonenumber != "-" ? (
              <a href={`tel: ${data?.data?.sellerPhonenumber}`}>
                <Image
                  src={callIcon}
                  alt={t("alt-icon")}
                  width={24}
                  height={24}
                  blurDataURL={rgbDataURL(255, 255, 255)}
                  style={{ objectFit: "contain" }}
                />
              </a>
            ) : (
              <a>
                <span
                // onClick={() => {
                //   onShowToast();
                // }}
                >
                  <Image
                    src={callIcon}
                    alt={t("alt-icon")}
                    width={24}
                    height={24}
                    blurDataURL={rgbDataURL(255, 255, 255)}
                    style={{ objectFit: "contain" }}
                  />
                </span>
              </a>
            )}
          </>
        ) : ( */}

        <a>
          <IconWrapper>
            <CustomCallIcon
              onClick={() => {
                setModalCallOpen(user && true);
              }}
            />
          </IconWrapper>
        </a>
      </>
    </IconButton>,

    <IconButton
      aria-label="vertical contained button group"
      key="chat"
      onClick={() => {
        event({
          action: "chat_clicked",
          params: {}
        });
        setChatOpen(true)

        // handleChat(data?.data?.sellerId, data?.data?.documentType);
      }}
      sx={{ p: 0 }}
    >
      {/* {isChatCreating ? (
        <CircularProgress size={20} />
      ) : ( */}
      <IconWrapper>
        <CustomMessageIcon />
      </IconWrapper>
      {/* )} */}
    </IconButton>,
    // <WhatsappShareButton
    //   url={data?.data?.documentType == 0
    //       ? `${WEB_PATH}/property/${data?.data?.id}`
    //       : data?.data?.documentType == 1
    //       ? `${WEB_PATH}/project/${data?.data?.id}`
    //       : `${WEB_PATH}/project-model/${data?.data?.id}`}
    //   title={data?.data?.propertyTypeText}
    //   separator=":: "
    //   children={data?.data?.media[0].imageThumbUrl}
    // >
    //   <WhatsappIcon size={32} round />
    // </WhatsappShareButton>,
    // <RWebShare
    //   data={{
    //     text: data?.data?.title,
    //     url: data?.data?.documentType == 0
    //       ? `${WEB_PATH}/property/${data?.data?.id}`
    //       : data?.data?.documentType == 1
    //       ? `${WEB_PATH}/project/${data?.data?.id}`
    //       : `${WEB_PATH}/project-model/${data?.data?.id}`,
    //     title: data?.data?.propertyTypeText,
    //   }}
    //   onClick={() => console.log("shared successfully!")}
    //   >
    //   <IconButton
    //     aria-label="vertical contained button group"
    //     key="share"
    //     //onClick={handleShareClick}
    //     sx={{ p: 0 }}
    //   >
    //     <IconWrapper>
    //       <CustomShareIcon />
    //     </IconWrapper>
    //   </IconButton>
    // </RWebShare>,
    <IconButton
      aria-label="vertical contained button group"
      key="share"
      onClick={handleShareClick}
      sx={{ p: 0 }}
    >
      <IconWrapper>
        <CustomShareIcon />
      </IconWrapper>
    </IconButton>,

    <Box key="favourite">
      <IconButton
        aria-label="vertical contained button group"
        onClick={() => {
          handleFavButtonSelect(data?.data?.id, isLiked);
        }}
        sx={{ p: 0 }}
      >
        {likeLoading || disLikeLoading ? (
          <CircularProgress size={28} sx={{ color: "#fff" }} />
        ) : (
          <>
               {isLiked == true ? (
              <IconWrapper fill>
                <CustomFavIconFill />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <CustomFavIcon />
              </IconWrapper>
            )}
          </>
        )}
      </IconButton>
      {likeCount > 0 && likeCount && (
        <Typography
          variant="body1"
          size="smallest"
          sx={{ color: "white.main", mt: "-5px", mb: "5px" }}
          textAlign={"center"}
        >
          {likeCount}
        </Typography>
      )}
    </Box>,

    <IconButton
      aria-label="vertical contained button group"
      key="more"
      onClick={() => {
        handleReport(data?.data?.documentType);
      }}
      sx={{ p: 0 }}
    >
      <IconWrapper>
        <CustomReportIcon />
      </IconWrapper>
    </IconButton>,
    <IconButton
      aria-label="vertical contained button group"
      key="edit"
      onClick={() => {
        handleEdit(data?.data?.documentType);
      }}
      sx={{ p: 0 }}
    >
      <IconWrapper small>
        <CustomEditIcon />
      </IconWrapper>
    </IconButton>, //edit icon will add
  ];

  const propertySlug = data?.data?.title?.replace(/,?\s+/g, '-').toLowerCase();

  const shareLinkAr = shortUrll

  // data?.data?.documentType == 0
  //   ? `${WEB_PATH}/property-details/${propertySlug}-${data?.data?.advertisementNumber}`
  //   : data?.data?.documentType == 1
  //     ? `${WEB_PATH}/project/${data?.data?.id}`
  //     : `${WEB_PATH}/project-model/${data?.data?.id}`;

  const shareLink = shortUrll

  // data?.data?.documentType == 0
  //   ? `${WEB_PATH}/en/property-details/${propertySlug}-${data?.data?.advertisementNumber}`
  //   : data?.data?.documentType == 1
  //     ? `${WEB_PATH}/en/project/${data?.data?.id}`
  //     : `${WEB_PATH}/en/project-model/${data?.data?.id}`

  return (
    <>
      <ActionsStyled>
        <Stack
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1.5}
        >
          {data?.data?.sellerId === user?.id
            ? [buttons[2], buttons[5]]
            : [buttons[0], buttons[1], buttons[2], buttons[3], buttons[4]]}
        </Stack>
      </ActionsStyled>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: "10px", display: "flex" }}>
          {/* <InlineShareButtons
            config={{
              alignment: "center", // alignment of buttons (left, center, right)
              color: "social", // set the color of buttons (social, white)
              enabled: true, // show/hide buttons (true, false)
              font_size: 16, // font size for the buttons
              labels: null, // button labels (cta, counts, null)
              language: router?.locale === "en" ? "en" : "ar", // which language to use (see LANGUAGES)
              networks: ["whatsapp", "facebook", "twitter", "linkedin"],
              padding: 12, // padding within buttons (INTEGER)
              radius: 4, // the corner radius on each button (INTEGER)
              show_total: false,
              size: 40, // the size of each button (INTEGER)

              // OPTIONAL PARAMETERS
              //data-url:`${WEB_PATH}property/${data?.data?.id}`,
              min_count: 10,
              url:
                data?.data?.documentType == 0
                  ? `${WEB_PATH}/property/${data?.data?.id}`
                  : data?.data?.documentType == 1
                  ? `${WEB_PATH}/project/${data?.data?.id}`
                  : `${WEB_PATH}/project-model/${data?.data?.id}`,
              username: data?.data?.sellerName,
            }}
          /> */}
          <CopyIconWrapper>
            <WhatsappShareButton
              url={router.locale == "ar" ? shareLinkAr : shareLink}
              title={data?.data?.title}
              separator=":: "
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </CopyIconWrapper>

          <CopyIconWrapper>
            <FacebookShareButton
              url={router.locale == "ar" ? shareLinkAr : shareLink}
              quote={data?.data?.title}
              hashtag={'#sanadak'}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </CopyIconWrapper>

          <CopyIconWrapper>
            <TwitterShareButton
              url={router.locale == "ar" ? shareLinkAr : shareLink}
              title={data?.data?.title}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </CopyIconWrapper>

          <CopyIconWrapper
            onClick={() => {
              copy(router.locale == "ar" ? shareLinkAr : shareLink);
            }}
          >
            {value ? <CopyIcon copied /> : <CopyIcon />}

            {/* <img
              alt="copy sharing button"
              src="https://platform-cdn.sharethis.com/img/copy.svg"
            /> */}
          </CopyIconWrapper>
        </Box>
      </Popover>
      <CustomDialog open={openModel} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <Report
            id={data?.data?.id}
            setOpen={setOpen}
            type={data?.data?.documentType}
          />
        </DialogContent>
        {/* <Modal open={openModel} onClose={handleClose}> */}
        {/* </Modal> */}
      </CustomDialog>

      {/* chat Modal */}
      <CustomDialog open={openChatModel} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <Chat
            id={data?.data?.id}
            setChatOpen={setChatOpen}
            data={data}
            handleChat={(sellerId, documentType) => handleChat(sellerId, documentType)}
            isChatCreating={isChatCreating}
          />
        </DialogContent>
      </CustomDialog>


      <Dialog open={modalCallOpen} fullWidth maxWidth="sm">
        <CloseButtonWrapper>
          <IconButton onClick={onCallPopupClose}>
            <CloseIcon />
          </IconButton>
        </CloseButtonWrapper>
        <DialogContent>
          <Box
            sx={{
              py: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h4Bold" size="big" sx={{ mb: "26px" }}>
              {t("contact_number")}
            </Typography>
            <div style={{ direction: "ltr", marginBottom: "40px" }}>
              {data?.data?.sellerPhonenumber &&
                data?.data?.sellerPhonenumber != "-" ? (
                <Typography variant="body2En" size="big" sx={{}}>
                  <a href={`tel: ${data?.data?.sellerPhonenumber}`}>
                    {data?.data?.sellerPhonenumber}
                  </a>
                </Typography>
              ) : (
                <Typography variant="body2En" size="big" sx={{}}>
                  {t("mobile_number_not_availble")}
                </Typography>
              )}
            </div>
            {data?.data?.sellerPhonenumber &&
              data?.data?.sellerPhonenumber != "-" && (
                <CustomButton
                  variant="contained"
                  type="submit"
                  wider="true"
                  onClick={() => {
                    event({
                      action: "contact_coppied",
                      params: {}
                    });
                    copy(data?.data?.sellerPhonenumber);
                  }}
                  disabled={value}
                //startIcon={otpVerifyLoading && <CircularProgress size={20} />}
                >
                  {value ? t("copied") : t("copy_the_contact_number")}
                </CustomButton>
              )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Actions;
